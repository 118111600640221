
import { Vue, Component } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import UserAccess from "@/model/User/UserAccess";
import IbotechService from "@/services/ibotechService";
import Multiselect from "vue-multiselect";
import * as XLSX from 'xlsx';


@Component({
  name: "Records",
  components: { Multiselect },
  computed: {
    ...mapGetters(["getAllRecordsData", "getAllLocationsData", "getAllVehiclesData", "getUserAccess", "isIbotech"]),
  },
  methods: {
    ...mapActions(["getVehicleRecordsByDate", "getAllLocations", "getAllVehiclesFun"])
  }

})
export default class Records extends Vue {
  readonly getUserAccess!: UserAccess;
  private getAllRecordsData!: Array<object>;
  private getAllLocationsData!: Array<any>;
  private getAllVehiclesData!: Array<any>;

  private getVehicleRecordsByDate!: Function;
  private getAllLocations!: Function;
  private getAllVehiclesFun!: Function;



  // Default
  perPage: number = 30;
  currentPage: number = 1;
  editRecord: boolean = false;
  loading = false;
  filter: string = "";
  searchInTableFor: string = "";
  reportForm = {
    from: "",
    to: ""
  }
  recordForm = {
    id: "",
    vehiclePlateNumber: "" as any,
    notice: "",
    locationId: "" as any,
    registrationDate: "",
    registrationTime: "",
    fullDayBooking: ""
  }
  fields: Array<string | any> = [
    {
      key: "selected",
      sortable: false,
      label: this.translateTableLabel('selected')
    },
    {
      key: "id",
      sortable: false,
      label: "ID"
    },
    {
      key: "vehicle",
      sortable: false,
      label: this.translateTableLabel('plateNumber')
    },
    {
      key: "location",
      sortable: false,
      label: this.translateTableLabel('location'),
    },
    {
      key: "registrationTime",
      sortable: false,
      label: this.translateTableLabel('registrationTime'),
    },
    {
      key: "notice",
      sortable: false,
      label: this.translateTableLabel('notice'),
    },
    {
      key: "actions",
      sortable: false,
      label: this.translateTableLabel('actions')
    }
  ];

  created() {

    // Redirect to 404
    if(!this.getUserAccess.AccessIbotech.VIEW.records) {
      this.$router.push({ name: 'NotFound' });
    }

    this.setDaysOfCurrentMonth();

    var data = {
      from: new Date(this.reportForm.from).toISOString(),
      to: new Date(this.reportForm.to).toISOString()
    }

    this.loading = true;

    // Get - Set Vehicle Data
    this.getVehicleRecordsByDate(data).then(() => {
      this.loading = false;
    });

    // Get - Set Location Data
    // -----------------------
    this.getAllLocations().then(() => {
    });

    // Get - Set Vehicle Data
    // -----------------------
    this.getAllVehiclesFun().then(() => {
    });

  }

  updateTableWithNewDate() {
    this.loading = true;

    if(typeof this.reportForm.from == 'object') {
      this.reportForm.from = new Date(this.reportForm.from).toLocaleDateString('sv-SE');
    }

    if(typeof this.reportForm.to == 'object')  {
      this.reportForm.from = new Date(this.reportForm.from).toLocaleDateString('sv-SE');
    }

    this.getVehicleRecordsByDate({
      from: new Date(`${this.reportForm.from}T00:00:00`).toISOString(),
      to: new Date(`${this.reportForm.to}T23:59:59`).toISOString()
    }).then(() => {
      this.loading = false;
    });

  }


  setDaysOfCurrentMonth() {

    const today = new Date();

    // Formatierung des Datums in String und Richtiges Format
    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    this.reportForm.from = formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
    this.reportForm.to = formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));

  }

  openRecordModal(action: string, data: any) {

    this.editRecord = false;
    this.resetRecordValues();

    if(action == 'edit') {
      this.editRecord = true;
      this.recordForm.id = data.item.id;
      this.recordForm.vehiclePlateNumber = data.item.vehicle;
      this.recordForm.notice = data.item.notice;
      this.recordForm.fullDayBooking = data.item.fulldayBooking;

      // RegisterTime
      // ------------
      var registrationTime = this.formatLocalDateTime(data.item.registrationTime.split("T")[0], data.item.registrationTime.split("T")[1])

      this.recordForm.registrationDate = `${registrationTime.year}-${registrationTime.month}-${registrationTime.day}`;
      this.recordForm.registrationTime = `${registrationTime.hours}:${registrationTime.minutes}`;

      // Location Type
      // -------------
      const locationType = this.getAllLocationsData.find(type => type.id === data.item.locationId);

      if(locationType) {
        this.recordForm.locationId = locationType
      } else {
        console.warn("Location type not found: ", locationType);
      }
    } else {
      this.recordForm.fullDayBooking = "1";
      this.recordForm.id = "1";
    }

    this.$bvModal.show('newRecord');

  }

  async createUpdateRecord() {

    if(this.recordForm.registrationTime == "00:00:00" || this.recordForm.registrationTime == "00:00") {
      this.recordForm.registrationTime = "00:00:01"
    }

    var registrationTime: any = this.formatLocalDateTime(this.recordForm.registrationDate, this.recordForm.registrationTime);

    if(this.isFormValid) {

      let res;

      var data: object = {
        registrationTime: `${registrationTime.year}-${registrationTime.month}-${registrationTime.day}T${registrationTime.hours}:${registrationTime.minutes}:${registrationTime.seconds}`,
        notice: this.recordForm.notice
      }

      if(this.editRecord) {

        data = {
          ...data,
          id: this.recordForm.id,
          vehiclePlateNumber: this.recordForm.vehiclePlateNumber,
          locationId: this.recordForm.locationId.id,
          fullDayBooking: this.recordForm.fullDayBooking
        }

        res = await IbotechService.postVehicleRecord(data);

      } else {

        data = {
          ...data,
          plateNumber: this.recordForm.vehiclePlateNumber.plateNumber,
          projectId: this.recordForm.locationId?.id,
        }

        res = await IbotechService.putVehicleRecordManuel(data);

      }

      if(res === "304") {

        this.$bvToast.toast( (this.$t('ibotech_vehicle.infoRecordAlreadyDoneText')).toString(), {
          title: (this.$t('ibotech_vehicle.infoRecordAlreadyDoneTitle')).toString(),
          autoHideDelay: 1500,
          variant: 'info'
        });

      } else if(res.status === 202) {
        this.success();
        this.$bvModal.hide('newRecord');
        this.updateTableWithNewDate();

      } else {
        this.error();
      }
    }


  }

  confirmDeleteRecord(id: any) {

    this.$bvModal
      .msgBoxConfirm((this as any).$t("ibotech_vehicle.deleteVehicle"), {
        title: (this as any).$t("inventory.deviceActions.archiveDevice.modalTitle"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          this.deleteRecord(id);

        } else {
          return false;
        }
      });
  }

  async deleteRecord(id: any) {

    const res = await IbotechService.deleteRecord(id);

    if(res.status == 202) {
      // Success Message
      this.$bvToast.toast( (this.$t('ibotech_vehicle.deleteSuccess')).toString(), {
        title: (this.$t('ibotech_vehicle.newVehicleForm.successMessageTitle')).toString(),
        autoHideDelay: 1500,
        variant: 'success'
      });

      this.updateTableWithNewDate();

    } else {
      this.error();
    }

  }

  async exportRecordsData() {

    if(this.reportForm.from && this.reportForm.to) {

      var data = {
        from: new Date(this.reportForm.from).toISOString(),
        to: new Date(this.reportForm.to).toISOString()
      }

      const res = await IbotechService.getVehicleRecordsByDate(data);

      if(res.status === 200) {
        this.success();

        res.data.forEach((item: any) => {
          delete item.location;
        });

        // Definieren der gewünschten Reihenfolge der Schlüssel
        const desiredOrder: string[] = ["id", "locationProjectId", "locationDescription", "vehicle", "registrationTime", "notice", "fulldayBooking"];

        // Sortieren der Daten gemäß der gewünschten Reihenfolge
        const sortedData = res.data.map((item: { [key: string]: any }) => {
          const sortedItem: { [key: string]: any } = {};
          desiredOrder.forEach((key: string) => {
            sortedItem[key] = item[key];
          });
          return sortedItem;
        });

        this.convertToXLSX(sortedData);

      } else {
        this.error();
      }

    }

  }

  convertToXLSX(data: any) {
    // Holen der Schlüssel aus dem ersten Datenobjekt und Übersetzen der Header
    const keys = Object.keys(data[0]);
    const translatedKeys = keys.map(key => this.$t(`ibotech_location.excelReport.${key}`).toString());

    // Formatieren der Datenzeilen
    const formattedData = this.formattedData(keys, data);

    // Erstellen des Arbeitsblatts
    const ws = XLSX.utils.json_to_sheet(formattedData, { header: translatedKeys });

    // Anpassen der Spaltenbreiten
    const columnWidths = this.calculateColumnWidth(translatedKeys, formattedData);
    ws['!cols'] = columnWidths;

    // Erstellen der Arbeitsmappe
    const wb = XLSX.utils.book_new();

    // Download der Excel-Datei
    this.downloadExcel(wb, ws);
  }

  downloadExcel(workbook: XLSX.WorkBook, worksheet: XLSX.WorkSheet) {
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'export.xlsx');
  }

  calculateColumnWidth(headers: string[], data: { [key: string]: any }[]): { wch: number }[] {
    return headers.map((header) => {
      const columnData = data.map(row => row[header] ? row[header].toString().length : 0);
      const headerLength = header.length;
      const maxLength = Math.max(headerLength, ...columnData);
      return { wch: maxLength + 2 };
    });
  }

  formattedData(keys: string[], data: any[]): { [key: string]: any }[] {
    return data.map((row: any) => {
      const formattedRow = { ...row };
      formattedRow.registrationTime = this.formatDateIntoGermanDate(row.registrationTime);
      formattedRow.fulldayBooking = this.translateFulldayBooking(row.fulldayBooking);
      return keys.reduce((acc: { [key: string]: any }, key: string) => {
        acc[this.translateExportLabel(key)] = formattedRow[key];
        return acc;
      }, {});
    });
  }

  formatDateIntoGermanDate(dateString: string): string {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const formattedTime = date.toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${formattedDate} ${formattedTime}`;
  }

  translateFulldayBooking(booking: boolean): string {
    return booking ? 'Ja' : 'Nein';
  }

  translateExportLabel(label: any) {
    return this.$t(`ibotech_location.excelReport.${label}`).toString();
  }

  success() {
    // Success Message
    this.$bvToast.toast((this.$t('ibotech_vehicle.deleteSuccess')).toString(), {
      title: (this.$t('ibotech_vehicle.newVehicleForm.successMessageTitle')).toString(),
      autoHideDelay: 1500,
      variant: 'success'
    });
  }

  error() {
    this.$bvToast.toast((this.$t('ibotech_vehicle.newVehicleForm.errorMessageText')).toString(), {
      title: (this.$t('ibotech_vehicle.newVehicleForm.errorMessageTitle')).toString(),
      autoHideDelay: 1500,
      variant: 'danger'
    })
  }

  formatLocalDateTime(dateString: any, timeString: any) {
    const date = new Date(dateString);
    const timeParts = timeString.split(':');

    // Setzen der Stunden und Minuten und Secunden
    date.setHours(timeParts[0], timeParts[1], timeParts[2]);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return {
      year: year,
      month: month,
      day: day,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    }
  }


  resetRecordValues() {
    this.recordForm = {
      id: "",
      vehiclePlateNumber: "",
      notice: "",
      locationId: "",
      registrationDate: "",
      registrationTime: "",
      fullDayBooking: ""
    }
  }

  filterActionHandler(filterAction: string) {
    this.filter = filterAction;
  }

  translateTableLabel(label: string) {
    return this.$t(`ibotech_vehicle.recordsTableHeadlines.${label}`);
  }

  get getData() {
    return this.getAllRecordsData;
  }

  get isFormValid() {
    return Object.entries(this.recordForm).every(([key, value]) => key === 'notice' || (value !== '' && value !== null) );
  }
}
